/* checkValidation v1.0 */
(function($) { 
	$.fn.checkValidation = function(options) {
		var _this = this;
		var $this = $(this);
		var opt = $.extend({
			trigger: false,
			onlyBoolean: false
		}, options, $this.data());

		var form = {
			$fields: null,
			result: true,
			radios: {},
			errorMessageId: '{{targetId}}Error', //LGEGMO-5525 START, LGEUS-685 20200710 modify
			init: function(){
				if($this.is('input, textarea, select')) {
					form.$fields = $this;
				}else {
					form.$fields = $this.find('input, textarea, select');
				}
				form.validation();
			},
			//LGEGMO-5525 START
			/* PJTWAUS-1 : 20200117 modify, LGEUS-268 : 20200330, LGEUS-685 20200710 modify */
			accessibility: function(t, condition) {
				var $t = $(t).removeAttr('aria-describedby'); // LGEUS-685 20200710 add
				var $errorMsg = $t.closest('.field-block').find(".error-msg");
				
				if ($errorMsg) {
					$errorMsg.find('> span').removeAttr('id');
					var errMsgId = form.errorMessageId.replace(/\{\{targetId\}\}/,t.id); // LGEUS-685 20200710 add
					var hiddenTextId = $(t).prev('.hiddenText').attr('id');
					var ariaDescribedbyVal = (!!hiddenTextId ? hiddenTextId + ' ' : '') + errMsgId;
					var $applyTarget = $t.is('select') ? $t.next('.chosen-container').find('>a') : $t;
					if (!condition) {
						$errorMsg.find('> span').each(function() {
							var $this = $(this);
							if ($this.is(':visible')) {
								$this.attr('id', errMsgId);
								return false;
							}
						});
					}
					$applyTarget.attr({'aria-describedby': ariaDescribedbyVal, 'aria-invalid': !condition});
				}
			},
			/* PJTWAUS-1 : 20200117 modify, LGEUS-268 : 20200330, LGEUS-685 20200710 modify */
			//LGEGMO-5525 END
			autoFocus: function(t){
				if(t.type.indexOf("select") >= 0 && $(t).is('.run-chosen')) {
					if(!$(t).is(':visible')) {
						$(t).trigger('chosen:activate');
					}else {
						$(t).focus();
					}
				}else {
					$(t).focus();
				}

				var $reTarget = $(t);
				if(t.type == 'radio') {
					$reTarget = check.$fieldBlock.find('input[name="'+t.name+'"]');
				}
				$reTarget.off('change.reconfirm').on({
					'change.reconfirm': function(e){
						var _field = e.currentTarget;
						form.validation(_field);

						$(_field).off('change.reconfirm');
					}
				});
			},
			validation: function(_targetField){
				var l,
					_fieldArray = [];

				if(_targetField) {
					_fieldArray = [_targetField];
				}else {
					_fieldArray = form.$fields;
				}

				l = _fieldArray.length;

				for (var i = 0; i < l; i++) {
					var t = _fieldArray[i];

					check.$fieldBlock = $(t).parents('.field-block');
					check.$errorMsg = check.$fieldBlock.find('.error-msg');

					if(!opt.onlyBoolean) {
						check.$fieldBlock.removeClass('error');
						check.$errorMsg.find('span').hide();
					}

					var condition = true;

					if($(t).is(":hidden") && t.type!="hidden"){
						var condition1 = t.type.indexOf("select") <= 0 && !$(t).is('.run-chosen'),
							condition2 = t.type.indexOf("select") <= 0 && $(t).siblings('.chosen-container').is(':hidden');
						if(condition1 || condition2) continue;
						// if is not select, skip this field;
					}else if($(t).is(":disabled")) {
						continue;
					}

					if(!check.required(t)) {// check required
						//LGEGMO-5525 START
						condition = false;
						//LGEGMO-5525 END
						if(!opt.onlyBoolean) {
							$(_this).find('.field-block').removeClass('error');
							check.$fieldBlock.addClass('error');
							//LGEGMO-5525 START
							form.accessibility(t, condition);
							//LGEGMO-5525 END
							form.autoFocus(t);
						}
						console.log('validation case 1');
						form.result = false;
						break;
					}else {
						// ie9 only
						if((t.type=="text" && t.getAttribute('data-type')) && (navigator.appVersion.indexOf("MSIE 9.")!=-1)) {
							t.setAttribute('type', t.getAttribute('data-type'));
						}
						
						// input condition validation LGEUS-919 
						var checkType = ['text', 'password', 'tel', 'email', 'number', 'file'];
						if(checkType.indexOf(t.type) >= 0) {
							// is text style input
							if(check.minLength(t)) {
								if(t.type == 'text') condition = check.pattern(t);
								if(t.type == 'email') condition = check.email(t);
								if(t.type == 'tel'){
									// LGEUS-1104 start
									if( t.getAttribute('data-type')=="phone"){
										condition = check.phone(t);
									}else{
										condition = check.tel(t);
									}
									// LGEUS-1104 end
								}
								if(t.type == 'file') condition = check.file(t); //LGEUS-919

								if(condition && t.getAttribute('data-match-target')) {
									condition = check.match(t);
								}
							}else {
								condition = false;
							}
						}else if(t.type == 'radio' || t.type == 'checkbox') {
							var selected = check.$fieldBlock.find('input[name="'+t.name+'"]:checked').get(0);
							// removed of unnecessary loops
							if(!form.radios[t.name] && selected) {
								form.radios[t.name] = {
									checkedField: selected
								};
								condition = check.radio(t);
							}else {
								continue;
							}
						}
					}
					if(!condition) {
						if(!opt.onlyBoolean) {
							check.$fieldBlock.addClass('error');
							//LGEGMO-5525 START
							form.accessibility(t, condition);
							//LGEGMO-5525 END
							form.autoFocus(t);
						}
						console.log('validation case 2');
						form.result = false;
						break;
					}else {
						form.result = true;
						
						if(!opt.onlyBoolean) {
							check.$fieldBlock.removeClass('error');
							//LGEGMO-5525 START
							form.accessibility(t, condition);
							//LGEGMO-5525 END

						}
					}
				}
			}
		};

		var check = {
			$fieldBlock: null,
			$errorMsg: null,
			required: function(t){
				var isEmpty,
					required = (t.getAttribute('required') != null) ? true : false;

				if($(t).is(":disabled")) required = false;
				if(t.type == 'checkbox' && (t.name == '' || t.name == undefined)) {
					isEmpty = !t.checked;
				}else if(t.type == 'radio' || (t.type == 'checkbox' && t.name)){
					var inputName = t.name;
					var $checkedField = check.$fieldBlock.find('input[name="'+inputName+'"]:checked');
					var checked = $checkedField.val();

					isEmpty = !checked ? true : false;
				}else {
					isEmpty = ($.trim($(t).val()) == "") || ($.trim($(t).val()) == null);
				}

				if(required && isEmpty) {// valiation error
					console.log(t.name);

					if(!opt.onlyBoolean) check.$errorMsg.find('.required').show();
					if(opt.trigger) $(t).trigger('invalid.required');
					console.log('check case 1');
					return false;
				} else {
					if(!opt.onlyBoolean) check.$errorMsg.find('.required').hide();
					if(opt.trigger) $(t).trigger('valid.required');
					return true;
				}
			},
			pattern: function(t){
				var pattern = t.getAttribute('pattern') ? t.getAttribute('pattern') : t.getAttribute('data-pattern');

				if(pattern && t.value != '') {
					pattern = (pattern == "\\d*") ? "^[0-9]*$" : pattern; // zip code only

					var val = t.value;
				var regex = new RegExp(pattern,"i");

					if(!regex.test(val)) {
						if(!opt.onlyBoolean) check.$errorMsg.find('.invalid').show();
						if(opt.trigger) $(t).trigger('invalid.pattern');
						console.log('check case 2');
						return false;
					}else {
						if(!opt.onlyBoolean) check.$errorMsg.find('.invalid').hide();
						return true;
					}
				}
				else return true;
			},
			// maxLength: function(t){
			// 	var max = t.getAttribute('data-max');
			// 	if(max) {
			// 		var length = t.value.length;
			// 		if(length > max) {// valiation error
			// 			// trigger invalid
			// 			if(opt.trigger) $(t).trigger('invalid.max');
			// 			return false;
			// 		}
			// 	}
			// 	else return true;
			// },
			minLength: function(t){
				var min = t.getAttribute('minLength');

				if(min) {
					var length = t.value.length;
					if(length < min && length > 0) {// valiation error
						// trigger invalid
						if(!opt.onlyBoolean) check.$errorMsg.find('.min-length').show();
						if(opt.trigger) $(t).trigger('invalid.min');
						return false;
					}else {
						if(!opt.onlyBoolean) check.$errorMsg.find('.min-length').hide();
						return true;
					}
				}
				else return true;
			},
			email: function(t){
				var val = t.value;
				var regex = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

				if(!regex.test(val)) {
					if(!opt.onlyBoolean) check.$errorMsg.find('.invalid').show();
					if(opt.trigger) $(t).trigger('invalid.email');
					return false;
				}else {
					if(!opt.onlyBoolean) check.$errorMsg.find('.invalid').hide();
					return true;
				}
			},
			tel: function(t){
				var val = t.value;
				// var regex = new RegExp(/^[+]?[0-9]{9,12}$/);
				var regex = new RegExp(/^[0-9-]*$/); //LGEUS-1245
				if(!regex.test(val)) {
					if(!opt.onlyBoolean) check.$errorMsg.find('.invalid').show();
					if(opt.trigger) $(t).trigger('invalid.tel');
					return false;
				}else {
					if(!opt.onlyBoolean) check.$errorMsg.find('.invalid').hide();
					return true;
				}
			},
			phone: function(t){
				// LGEUS-1104 start
				var val = t.value;
				//DC-531 START
				var regex = new RegExp(/^[+()]*([0-9]{3})[-()]*([0-9]{3})[-()]*([0-9]{4}|[a-zA-Z]{4})$/);
				//DC-531 END
				console.log(regex.test(val), val);
				if(!regex.test(val) && val.length > 0) {
					check.$errorMsg.find('.phone').show();
					if(opt.trigger) $(t).trigger('invalid.phone');
					return false;
				}else {
					check.$errorMsg.find('.phone').hide();
					return true;
				}
				// LGEUS-1104 end
			},
			match: function(t){
				// match check;
				if(t.value != $(t.getAttribute('data-match-target')).val()) {
					// console.log(check.$errorMsg);
					if(!opt.onlyBoolean) check.$errorMsg.find('.match').show();
					return false;
				}else {
					if(!opt.onlyBoolean) check.$errorMsg.find('.match').hide();
					return true;
				}
			},
			radio: function(t){
				var _selected = form.radios[t.name].checkedField,
					_related = _selected.getAttribute('data-related-input');
				if(_related && $(_related).val() == '') { // is it related text input was empty?
					var _$relatedBlock = $(_related).parent('.field-block');
					if(!opt.onlyBoolean) {
						_$relatedBlock.find('.error-msg .required').show();
						$(_related).focus();
					}
					return false;
				}else {
					return true;
				}
			},
			//LGEUS-919 Start
			file: function(t){
				if(!t.files[0]) return true;
				
				// check file size
				var maxSize = $(t).data('max'),
					extension = $(t).data('extension'), // 허용되는 파일 확장자 목록, |로 구분 되어 있음 
					filesize = 0,
					browser = navigator.appName,
					file = $(t).get(),
					ext,
					fileExtension;

				if (!maxSize && !extension) return true;

				if (maxSize.indexOf('MB')!=-1) {
					maxSize = parseInt(maxSize.split('MB')[0])*1024*1024;
				}
				if (window.FileReader){
					fileSize = $(t)[0].files[0].size;
					ext = $(t)[0].files[0].name;
				} else {
					var fileobj = new ActiveXObject("Scripting.FileSystemObject");
					fileSize = fileobj.getFile( file.value ).size;
					ext = $(t).val().split('/').pop().split('\\').pop();
				}

				// Check file size
				if (fileSize > maxSize) {
					if(!opt.onlyBoolean) check.$errorMsg.find('.size').show();
					if(opt.trigger) $(t).trigger('invalid.file');
					return false;
				}

				// Check file extension
				fileExtension = ext.split('.')[ext.split('.').length-1].toLowerCase();
				
				if($.inArray(fileExtension, extension.split('|')) == -1) {
					if(!opt.onlyBoolean) check.$errorMsg.find('.invalid').show();
					if(opt.trigger) $(t).trigger('invalid.file');
					return false;
				}

				if(opt.trigger) $(t).trigger('valid.file');
				return true;
			}
			//LGEUS-919 End
		};

		this.each(function() {
			form.init();
		});

		return form.result;
	};
})(jQuery);

$(document).ready(function(){
	$('input[type="radio"], input[type="checkbox"]').on({
		change: function(e){
			var _this = e.currentTarget,
				_target = _this.getAttribute('data-related-input'),
				$block = $(_this).parents('.field-block');

			// LGCOMUS-246
			$block.removeClass('error');

			if(_this.checked) {
				$block.find('input[type="text"]').attr('disabled','disabled');
				if(_target) {
					/* PJTWAUS-1 : 20200315 modify */
					console.log('');
					$(_target).removeAttr('disabled').attr('required','required');
					console.log('');
					/*// PJTWAUS-1 : 20200315 modify */
				}
			}else {
				$(_target).attr('disabled','disabled').removeAttr('required');
			}
		}
	});
	$('.auto-validation-form').on({
		submit: function(e){
			// e.preventDefault();
			var $this = $(this);
			if(!$this.checkValidation()){
				e.preventDefault();
				$this.trigger('validationFail', [this]); // validation result event trigger
			}else {
				// console.log('submit!');
				$this.trigger('validationSuccess', [this]); // validation result event trigger
			}
		}
	});
});